

const NavbarPg = () => {
  return (
    <nav
      className="lg:p-11 p-7 z-10"
      style={{ backgroundColor: '#003140' }}
    ></nav>
  );
};

export default NavbarPg;

import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import aliHasan from '../../../assets/Ali Hasan.jpeg'
import imran from '../../../assets/Imran.jpeg'
import mahamud from '../../../assets/Mahmud.jpeg'
import rakesh from '../../../assets/Rakesh.jpeg'
import sadia from '../../../assets/Sadia.jpeg'

const Card = () => {
  const cardsData = [
    {
      imageSrc: imran,
      name: 'Imran Hossain',
      role: 'Co Founder',
      linkedin:'https://www.linkedin.com/in/emrran/ '
    },
    {
      imageSrc: rakesh,
      name: 'Rakesh Das',
      role: 'Co Founder ',
      linkedin: "https://www.linkedin.com/in/dasrakesh03/"
    },
    {
      imageSrc: sadia,
      name: 'Sadia Islam',
      role: 'Head of operations',
      linkedin:' https://www.linkedin.com/in/sadiaislamriu/'

    },
    {
      imageSrc: aliHasan,
      name: 'Ali Hasan',
      role: 'Head of Engineering',
      linkedin: "https://www.linkedin.com/in/alihasannsu/"

    },
    
    {
      imageSrc: mahamud,
      name: ' Mahmudul Hasan',
      role: 'Head of Sales & Business Development',
      linkedin: "https://www.linkedin.com/in/mahmudulhasan95/"
    }
    
    
   
    
  ];

  return (
    <Swiper
    spaceBetween={6}
    grabCursor={true}
    navigation={true}
    modules={[Navigation]}


    breakpoints={{
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
      },
      // when window width is >= 768px
      768: {
        slidesPerView: 3,
      },
      // when window width is >= 1024px
      1024: {
        slidesPerView: 4,
      },
      
      
    }}
  >
        <div className="grid lg:grid-cols-2 xl:grid-cols-4 md:grid-cols-2">

     {cardsData.map((card, index) => (
        <SwiperSlide key={index}>
          <div className="relative mx-10  flex flex-wrap flex-col text-gray-700 bg-white shadow-md bg-clip-border rounded-xl  mb-10 ">
            <div className="relative overflow-hidden text-gray-700 bg-white bg-clip-border rounded-xl">
              <img
                className="w-full h-80 object-cover hover:scale-110 duration-200 rounded-bl-[100px] rounded-br-[500px]"
                src={card.imageSrc}
                alt={`${card.name}'s profile`}
              />
            </div>
            <div className="p-2 text-center">
              <h4 className="block font-sans text-2xl font-semibold leading-snug text-blue-900">
                {card.name}
              </h4>
              <p className="block font-sans text-base font-medium leading-relaxed bg-clip-text bg-gradient-to-r from-blue-900 to-blue-gray-400  ">
                {card.role}
              </p>
            </div>
            <div className="m-auto p-4">
              <a target='_blank' href={card.linkedin} rel="noreferrer" >
                <img src="https://static.vecteezy.com/system/resources/previews/023/986/970/non_2x/linkedin-logo-linkedin-logo-transparent-linkedin-icon-transparent-free-free-png.png" alt="LinkedIn" className="w-8 h-8" />
              </a>
            </div>
          </div>
        </SwiperSlide>
    ))}
    </div>
  </Swiper>
);
};

export default Card;

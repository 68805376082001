import React from 'react';
import { Link } from 'react-router-dom';


//dark:bg-gray-800
const WhyChooseUS = () => {
  
  return (
    <div className="mt-32">
      <div className=" grid grid-cols-1 md:grid-cols-2 p-6 md:p-12 rounded-lg shadow-lg lg:w-full  "style={{ backgroundColor: '#003140' }}>
        <div className=" m-auto">
          <h2 className="text-4xl md:text-5xl lg:text-5xl  font-extrabold text-white leading-tight hover:border-gray-500 hover:border-dotted hover:border-2

transition-all duration-500 ease-in hover:p-4">
            WHY CHOOSE <span className="text-green-400">QRYSALIS.</span>
          
          </h2>
        </div>
        <div className="md:col-span-1  m-4 lg:m-0 md:m-2   text-white  ">
          <div>

          </div>
          <div className="list-none lg:grid grid-cols-2 lg:p-5  gap-11 space-y-7 lg:space-y-0 lg:text-lg md:text-sm text-sm">
            <li className=''>
            <span className="text-blue-400 mr-2 text-xl">&#8226;</span>

              <span className="font-bold text-xl">Expertise:
              </span>
              <span className='text-[16px]'> Our team comprises of professionals with a wealth of experience in their respective fields, ensuring the delivery of high-quality solutions and unparalleled expertise. </span>
            </li>
            <li>
            <span className="text-blue-400 mr-2 text-xl">&#8226;</span>

              <span className="font-bold text-xl">Innovation:
             </span> 
             <span className='text-[16px]'> We are committed to staying ahead of the curve by embracing emerging technologies and innovative solutions to address the evolving needs of our clients. </span>
            </li>
            <li>
            <span className="text-blue-400 mr-2 text-xl">&#8226;</span>

              <span className="font-bold text-xl">Customization:
              </span> 
              <span className='text-[16px]'> At Qrysalis, we understand that one size does not fit all. That's why we offer tailored solutions designed to meet the unique requirements and objectives of each client. </span>
            </li>
            <li>
            <span className="text-blue-400 mr-2 text-xl">&#8226;</span>

              <span className="font-bold text-xl">Customer-Centric Approach:
            </span> 
            <span className='text-[16px]'> Our clients are at the heart of everything we do. We prioritize open communication, transparency, and collaboration to ensure a seamless and rewarding experience. </span>
            </li>
            <li>
            <span className="text-blue-400 mr-2 text-xl">&#8226;</span>

              <span className="font-bold text-xl">Results-Driven:
             </span> 
             <span className='text-[16px]'> We measure our success by the success of our clients. Whether it's increasing revenue, optimizing processes, or enhancing customer satisfaction, we are committed to delivering measurable results that drive business growth. 

</span>
            </li>
           
          </div>
          <div className="lg:p-4 flex">
          <Link to="contact" className="inline-block">
            <p className="border lg:w-[150px] w-32 p-3 shadow-md focus:outline-none transition duration-300 ease-in-out transform hover:scale-105 text-center text-teal-400 font-bold  rounded-full lg:text-lg text-sm font-mono border-teal-200  border-b-4 hover:border-teal-400">
                LET"S TALK
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUS;

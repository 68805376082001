import { BrowserRouter,Routes,Route } from "react-router-dom";
import Layout from "./Components/Layout/Layout";
import Home from "./Components/Home/Home";
import Contact from "./Pages/Contact/Contact";


function App() {
  return (
    <BrowserRouter>
    <Routes>
   <Route  element={<Layout/>}>
   <Route index element={<Home/>}/>
   <Route path="/contact" element={<Contact/>}/>

   </Route>

    </Routes>
      
    </BrowserRouter>
  );
}

export default App;

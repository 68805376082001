import React from 'react'
import { Link } from 'react-router-dom'
import '../Home/AchiveGoal/Button.css'

const Button = () => {
  return (
    <div className="  pt-6 sm:pt-9 flex">
          <Link to="contact" className="inline-block">
            <p className="border lg:w-[150px] w-32 p-3 shadow-md focus:outline-none transition duration-300 ease-in-out transform hover:scale-105 text-center text-teal-400 font-bold  rounded-full lg:text-lg text-sm font-mono border-teal-200  border-b-4 hover:border-teal-400">
         TALK TO US
      </p>
    </Link>
  </div>
  )
}

export default Button
import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

const AchiveGoal = () => {
  return (
    <div className="mt-20 text-gray-800">
      <div className="flex flex-col lg:flex-row m-4">
        <div className="lg:w-1/2">
          <img
            src="https://unblast.com/wp-content/uploads/2020/03/Illustrator-Vector-Illustration-1.jpg"
            alt=""
            className="w-full object-cover"
          />
        </div>
        <div className=" lg:w-1/2 lg:ml-20 p-7 container mx-auto">
          <p className="xl:text-7xl lg:text-4xl text-wrap font-bold text-2xl text-gray-800">
          Don't just play the game. Change the game.           </p>
          <p className="font-light text-sm lg:text-xl lg:pt-11 pt-4 leading-relaxed lg:leading-loose tracking-wider text-wrap">
          Elevate beyond mere players and become architects of innovation. Redefine the rules, rewrite the narrative, and shape the future of your industry. Join us in co-creating possibilities for a stronger tomorrow.           </p>
        
          
        </div>
      </div>
    </div>
  );
};

export default AchiveGoal;

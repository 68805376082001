import React from 'react';
import { Link } from 'react-router-dom';

const LetUSHelp = () => {
  return (
    <div className="mt-32  text-gray-800 bg-blue-50" id='contact'> 
    
        <div className="lg:flex grid ">
        <div className='w-full md:max-w-xs lg:max-w-md xl:max-w-lg m-auto pt-4 lg:pt-0 '>
            <img
              src='https://img.freepik.com/premium-vector/home-office-concept-man-working-from-home-student-freelancer-freelance-studying-concept-vector-illustration-flat-style_186332-478.jpg?size=626&ext=jpg&ga=GA1.1.85220770.1710045673&semt=ais'
              alt=''
              className='w-full h-auto object-cover rounded-full  '
            />
          </div>
          
          <div className="md:w-1/2 p-8 md:p-16 lg:p-20">
            <div className=" md:text-left lg:ml-32 text-left">
              <h2 className="text-4xl md:text-5xl lg:text-6xl  font-bold mb-4 leading-tight">
                Let us help you <br /> deliver your next
                <br /> project!
              </h2>
              <p className="text-lg md:text-xl lg:text-2xl mb-6 lg:leading-loose">
                We are committed to providing innovative solutions tailored to
                your needs.
              </p>
              <div className="  pt-6 sm:pt-9 flex">
          <Link to="contact" className="inline-block">
            <p className="border lg:w-[150px] w-32 p-3 shadow-md focus:outline-none transition duration-300 ease-in-out transform hover:scale-105 text-center text-teal-400 font-bold  rounded-full lg:text-lg text-sm font-mono border-teal-200  border-b-4 hover:border-teal-400">
            CONTACT US

            </p>
          </Link>
        </div>
            </div>
          </div>
          
        </div>
      
    </div>
  );
};

export default LetUSHelp;

import React from 'react';
import { Link } from 'react-router-dom';

const TheQrysailsMisson = () => {
  return (
    <div className="mt-32">
      <div
        className=" grid grid-cols-1 md:grid-cols-2 p-6 md:p-12 rounded-lg shadow-lg lg:w-full  "
        style={{ backgroundColor: '#003140' }}
      >
        <div className=" m-auto">
          <h2 className="text-4xl md:text-5xl lg:text-5xl  font-extrabold text-white leading-tight hover:border-gray-500 hover:border-dotted hover:border-2

transition-all duration-500 ease-in hover:p-4">
          WHERE WE MAKE A<br/>   <span className="text-green-400">DIFFERENCE.</span>
          </h2>
        </div>
        <div className="md:col-span-1  m-4 lg:m-0 md:m-2  space-y-6  text-white  ">
          <div></div>
          <ul className="list-none lg:grid grid-cols-2  lg:p-5 gap-5 space-y-5 lg:space-y-0 lg:text-lg md:text-sm text-sm">
            <li className="">
              <span className="text-blue-400 mr-2">&#8226;</span>

              <span className="font-bold ">E-COMMERCE </span>
            
            </li>
            <li>
              <span className="text-blue-400 mr-2">&#8226;</span>

              <span className="font-bold">HEATHCARE</span>
              
            </li>
            <li>
              <span className="text-blue-400 mr-2">&#8226;</span>

              <span className="font-bold">FINANCE </span>
              
            </li>
            <li>
              <span className="text-blue-400 mr-2">&#8226;</span>

              <span className="font-bold">MANUFACTURING </span>
             
            </li>
            <li>
              <span className="text-blue-400 mr-2">&#8226;</span>

              <span className="font-bold ">RETAIL</span>
              
            </li>
            <li>
              <span className="text-blue-400 mr-2">&#8226;</span>

              <span className="font-bold ">HOSPITALITY</span>
              
            </li>
            <li>
              <span className="text-blue-400 mr-2">&#8226;</span>

              <span className="font-bold ">EDUCATION </span>
              
            </li>
            <li>
              <span className="text-blue-400 mr-2">&#8226;</span>

              <span className="font-bold ">TECNOLOGY </span>
              
            </li>
          </ul>
          <div className=" lg:p-4 pt-6 flex">
          <Link to="contact" className="inline-block">
            <p className="border lg:w-[150px] w-32 p-3 shadow-md focus:outline-none transition duration-300 ease-in-out transform hover:scale-105 text-center text-teal-400 font-bold  rounded-full lg:text-lg text-sm font-mono border-teal-200  border-b-4 hover:border-teal-400">
              TELL US
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TheQrysailsMisson;

import React from 'react'
import Hero from './Hero/Hero'
import AchiveGoal from './AchiveGoal/AchiveGoal'
import ElimiteFailure from './Eliminate failure/ElimiteFailure'
import ScheduleCall from './What makes us/ScheduleCall'
import OurClients from './Our Clients/OurClients'
import TheQrysailsMisson from './The Qrysalis Mission/TheQrysailsMisson'
import WhyChooseUS from './WHy choose US/WhyChooseUS'
import LetUSHelp from './Let us help/LetUSHelp'
import Team from './Team/Team'

const Home = () => {
  
 
  return (
    <div>
        <Hero/>
        <AchiveGoal/>
        <ElimiteFailure/>
        <TheQrysailsMisson/>
        <ScheduleCall/>
        {/* <OurClients/> */}
        <WhyChooseUS/>
        <Team/>
        <LetUSHelp/>
        
    </div>
  )
}

export default Home
import React, { useEffect, useState } from 'react'
import Button from '../Button/Button';

const ScrollToButton = () => {
    const [isVisible,setIsVisible]=useState(false)

    const handleScroll=()=>{
        if(window.scrollY > 300){
            setIsVisible(true);
        }else{
            setIsVisible(false);
        }
    }

    const scrollToTop =()=>{
        window.scrollTo({
            top:0,
            behavior:'smooth'
        })
    }

    useEffect(()=>{
        const cleanup=()=>{
            window.removeEventListener('scroll',handleScroll)
        }
        window.addEventListener('scroll',handleScroll)
        return cleanup
    },[])
  return (
    <button
    className={`fixed bottom-10  right-4 z-50 p-2 bg-blue-700 text-white rounded-full ${
      isVisible ? 'visible' : 'hidden'
    }`}
    onClick={scrollToTop}
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" />
</svg>

  </button>
  )
}

export default ScrollToButton
import React from 'react'
import png1 from '../../../assets/Home.png'
const Hero = () => {
  return (
    <div className='justify-center  ' id='home'>
  <div className='relative'>

    <div className="absolute inset-0 bg-black opacity-60"></div>
    <div className=''>

      <img src={png1} alt='' className='w-full object-cover lg:h-screen' />
    </div>

    <div className='absolute top-[30%] md:top-[25%] lg:top-[20%] xl:top-[40%] ml-[4%] md:ml-[3%] xl:ml-[8%] '>
  <h1 className='text-xl  md:text-3xl lg:text-7xl font-bold text-white '>
    Shed Limitation,<br/> Embrace Innovation
  </h1>
  <p className='text-sm md:text-md lg:text-2xl font-light text-white mt-2 lg:mt-8 xl:block lg:block md:hidden sm:hidden hidden'>
    Empowering People, Businesses and Financial Institutions to reach their next iteration.
  </p>
  <p className='text-sm md:text-md lg:text-2xl font-light text-white mt-4 lg:mt-8 xl:hidden lg:hidden md:block sm:block block'>
    Empowering People, Businesses and Financial <br/> Institutions to reach their next iteration.
  </p>
  {/* <div className='hidden sm:block  md:block lg:block xl:block mt-4 w-48'>
    <Button />
  </div> */}
</div>

  </div>
</div>

  )
}

export default Hero
import React from 'react'
import Card from './Card'

const Team = () => {
  return (
    // <div className=' bg-blue-50'>
    //     <h2 className='lg:text-7xl text-center text-blue-400 font-bold text-3xl mt-10'>Team</h2>
    //     <div className='flex justify-center mt-6'>
    //     <Card/>
      

    //     </div>
       
    // </div>
 <section className=" text-center p-5 mt-32 bg-blue-50" id='team'>
  <h1 className='lg:text-7xl text-center text-blue-400 font-bold text-3xl lg:mt-5'>Team</h1>
  <div className='flex justify-center mt-6  max-w-screen-2xl mx-auto '>
        <Card/>
      

         </div>
 </section>
  )
}

export default Team
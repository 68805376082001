import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className=" text-white lg:p-4 p-8 mt-32"style={{ backgroundColor: '#003140' }}>
      <div className="container  mx-auto mt-20 grid lg:flex lg:flex-col-4 sm:grid-cols-2 md:grid-cols-3 grid-cols gap-11">

        
        <div className="w-full md:w-1/2 lg:w-1/3 mb-4 md:mb-0 space-y-6">
          <div className="text-white text-xl font-bold mb-4 ">QRYSAILS</div>
        <div className=' space-y-4'>
        <span className='font-bold flex gap-2 '>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
</svg>ADDRESS</span> 
          <p className=''>
         

          Navana Tower (Level#7-C),45, Gulshan Circle-1, Dhaka-1212.
          </p>
        </div>
        <div className=" mt-3 w-full md:w-1/2 lg:w-1/3 mb-4 md:mb-0">
          <div className="text-white font-bold  mb-4 flex gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
</svg>
EMAIL</div>
          
          <p>info@qrysalis.com
            
          </p>
        </div>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/3 mb-4 md:mb-0">
          <div className="text-white font-bold mb-4 ">EXPLORE</div>
          <div>

          
          <ul className=" flex-wrap space-y-4">
            <li className="w-1/2 md:w-1/3 lg:w-1/2 mb-2 md:mb-0">
              <a href="#" className="text-white hover:text-gray-300 block">
                Home
              </a>
            </li>
            <li className="w-1/2 md:w-1/3 lg:w-1/2 mb-2 md:mb-0">
              <a href="#" className="text-white hover:text-gray-300 block">
                About
              </a>
            </li>
            <li className="w-1/2 md:w-1/3 lg:w-1/2 mb-2 md:mb-0">
              <a href="#" className="text-white hover:text-gray-300 block">
                Services
              </a>
            </li>
            <li className="w-1/2 md:w-1/3 lg:w-1/2 mb-2 md:mb-0">
              <a href="#" className="text-white hover:text-gray-300 block">
                Portfolio
              </a>
            </li>
            <li className="w-1/2 md:w-1/3 lg:w-1/2 mb-2 md:mb-0">
              
            </li>
            <li className="w-1/2 md:w-1/3 lg:w-1/2 mb-2 md:mb-0">
              <a href="#" className="text-white hover:text-gray-300 block">
                Contact us
              </a>
            </li>
            
          </ul>
          <div>


            
          </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/3 mb-4 md:mb-0">

        <div className="text-white font-bold mb-4">FOLLOW</div>
          <div className="grid space-y-4">
            <a href="#" className="text-white hover:text-gray-300">
              Facebook
            </a>
            <a href="#" className="text-white hover:text-gray-300">
              Twitter
            </a>
            <a href="#" className="text-white hover:text-gray-300">
              Linkedin
            </a>
           
            
          </div>
          </div>
    
        <div className="w-full md:w-1/2 lg:w-1/3 mb-4 md:mb-0">
          <div className="text-white flex font-bold mb-4">NEWSLETTER</div>
          <p>Get our stuff in your mail.</p>
          info@qrysalis.com
          <div className='container pt-11 flex'>
        <Link to='https://www.linkedin.com/company/qrysalis'>
          <img src='https://static.vecteezy.com/system/resources/thumbnails/023/986/613/small_2x/facebook-logo-facebook-logo-transparent-facebook-icon-transparent-free-free-png.png' alt='facebook' className='rounded-full w-10 h-10 m-1' />
        </Link>
        <Link to='https://twitter.com/'>
          <img src='https://cdn.icon-icons.com/icons2/4029/PNG/512/twitter_x_new_logo_x_rounded_icon_256078.png' alt='twitter' className='rounded-full h-10 m-1' />
        </Link>
        <Link to='https://www.facebook.com/qrysalis'>
          <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/f/f8/LinkedIn_icon_circle.svg/2048px-LinkedIn_icon_circle.svg.png' alt='linkedin' className='rounded-full h-8 m-2' />
        </Link>
      </div>
        </div>
        </div>
        <div className='mt-11 container mx-auto'>
        <hr/>
        <div className='flex justify-between  mt-28'>

        
        <p className="text-gray-500">&copy; 2024 Qrysalis. All Rights Reserved.</p>
         
          <p className="text-gray-500">Join Us | <a href="#">FAQs</a> | <a href="#">Privacy Policy</a></p>
          </div>
        </div>
        
    </footer>
  );
};

export default Footer;

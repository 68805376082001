import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import slides from './Data';

import { Navigation } from 'swiper/modules';

import './Style.css'

const ScheduleCall = () => {




  return (
    <div className="mt-32 lg:p-0 p-4 lg:mx-10 " id='service'>
      <Swiper
        spaceBetween={6}
        grabCursor={true}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper "
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 2,
          },
          1400: {
            slidesPerView: 4,
          },
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index} className="relative">
          <div className="overflow-hidden lg:h-[80vh] md:h-none">
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <img src={slide.image} alt={slide.title} className="object-cover" />
          </div>
          <div className="absolute inset-0 flex flex-col lg:top-[400px] top-48 justify-center text-center px-4 md:px-8 lg:px-12 text-white hover-effect">
            <h2 className="text-[12px] font-bold mb-2">
              <span className="text-white text-xl lg:text-4xl hover:hidden">{slide.title}</span>
            </h2>
          </div>
          <div className="absolute inset-0 flex items-center justify-center bg-gradient-to-t from-transparent to-[#003140] hover:opacity-100 opacity-0 transition-opacity duration-500">
  <div className="text-white text-wrap px-4 py-2 rounded-lg shadow-md hover:shadow-lg transition-all md:px-6 md:py-3 md:text-lg text-sm bg-transparent ease-in duration-500">
    {slide.description}
  </div>
</div>
        </SwiperSlide>
        ))}
      </Swiper>
   
    </div>
  );
};

export default ScheduleCall;

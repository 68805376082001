import React from 'react';
import './Card.css';
import png1 from '../../../assets/Home(1).png';

const ElimiteFailure = () => {
  return (
    <div className="mt-32  text-gray-800 " id='about'>
      <div>
        <h2 className="lg:text-7xl font-bold text-2xl text-center ">
          How We Help{' '}
        </h2>
      </div>
      <div className="flex flex-col lg:flex-row font-bold   justify-center lg:gap-48 gap-4 ">
        <div className="m-auto mt-20  xl:ml-48  mx-10 ">
          <div className="cards   ">
            <div className="card shadow-xl shadow-blue-100 border-teal-950 border- border-l-8 border-solid p-4 ">
              <div className="flex flex-row gap-4">
                <div className="m-auto p-2">
                  <img
                    src="https://toppng.com/uploads/preview/our-service-icon-png-our-services-icon-11562927206xbiiaqynhu.png"
                    alt="1"
                    className=" w-14 rounded-full"
                  />
                </div>
                <div className="text-left">
                  <p className="tip text-black  ">
                    Digitally Transform Your Business
                  </p>
                  <p className="second-text text-black text-wrap overflow-hidden md:block lg:block xl:block sm:hidden hidden">
                    Leverage our expertise in web and software development to
                    modernize your operations, enhance customer experiences, and
                    stay ahead of the competition.
                  </p>
                </div>
              </div>
            </div>
            <div className="card shadow-xl ml-6 shadow-blue-100 border-blue-900 border- border-l-8 border-solid p-4">
              <div className="flex flex-row gap-4">
                <div className="m-auto p-2">
                  <img
                    src="https://energizeit.com/wp-content/uploads/2017/07/managed-services-icon.png"
                    alt="2"
                    className=" w-14 rounded-full"
                  />
                </div>
                <div className="text-left">
                  <p className="tip text-black  ">Eliminate Failure</p>
                  <p className="second-text text-black md:block lg:block xl:block sm:hidden hidden">
                    Ensure business continuity and minimize downtime with our
                    reliable infrastructure services and managed IT solutions,
                    designed to keep your operations running smoothly.{' '}
                  </p>
                </div>
              </div>
            </div>
            <div className="card shadow-xl shadow-blue-100 border-teal-950 border- border-l-8 border-solid p-4">
              <div className="flex flex-row gap-4">
                <div className=" p-2 m-auto">
                  <img
                    src="https://cdn.iconscout.com/icon/premium/png-256-thumb/business-process-optimization-4256577-3529799.png"
                    alt="3"
                    className=" w-20 rounded-full"
                  />
                </div>
                <div className="text-left">
                  <p className="tip text-black  ">Increase Efficiency</p>
                  <p className="second-text text-black text-wrap md:block lg:block xl:block sm:hidden hidden">
                    Streamline your operations and automate repetitive tasks
                    with our optimization and automation solutions, freeing up
                    resources to focus on growth and innovation.{' '}
                  </p>
                </div>
              </div>
            </div>
            <div className="card shadow-xl ml-6 shadow-blue-100 border-blue-900 border- border-l-8 border-solid p-4">
              <div className="flex flex-row gap-4">
                <div className=" p-1 m-auto">
                  <img
                    src="https://static.vecteezy.com/system/resources/previews/009/990/627/non_2x/automation-icon-isolated-contour-symbol-illustration-vector.jpg"
                    alt="4"
                    className=" w-20 rounded-full"
                  />
                </div>
                <div className="text-left ">
                  <p className="tip text-black  ">Attract New Customer Base</p>
                  <p className="second-text text-black text-wrap md:block lg:block xl:block sm:hidden hidden  overflow-hidden">
                    Reach new heights with our digital marketing strategies,
                    designed to increase brand visibility, drive engagement, and
                    attract a loyal customer following.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full lg:h-[730px]  bg-none mt-11 ">
          <div className="lg:w-[90%] lg:h-[90%] lg:mt-[10%] xl:ml-16   ">

            <img
              src={png1}
              alt="4"
              className="w-full lg:h-[600px] lg:p-0 p-4  object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElimiteFailure;

import React, { useState } from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import NavbarPg from './Navbar/NavbarPg';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    budget: '',
    message: '',
  });

  const [formFilled, setFormFilled] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (Object.values(formData).some((value) => !value.trim())) {
      alert('Please fill in all the required fields.');
      return;
    }

    console.log('Form submitted:', formData);

    setFormFilled(true);

    setFormData({
      name: '',
      email: '',
      company: '',
      budget: '',
      message: '',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <>
    <NavbarPg/>
    
    <div className="bg-gray-100 p-3 grid lg:grid-cols-2">
     
      <div>
        <img src='https://bagtyyaryoda.com/images/contact_us.png' alt='png' className=''/>
      </div>
      <div className=''>

      
      <div className=" bg-white p-8 rounded-md shadow-md h-full ">
        <h1 className="lg:text-5xl text-2xl  font-bold mb-4">Contact Us</h1>
        <p className="mb-6 font-light text-sm">We are one click away to start your project</p>

        <form onSubmit={handleSubmit} className="space-y-4 ">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-600">
              Name
            </label>
            <input
            
              type="text"
              id="name"
              name="name"
              className="mt-1 p-2 w-full border rounded-md border-gray-400 "
              value={formData.name}
              onChange={handleChange}
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-600">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="mt-1 p-2 w-full border rounded-md border-gray-400 "
              value={formData.email}
              onChange={handleChange}
            />
          </div>

          <div>
            <label htmlFor="company" className="block text-sm font-medium text-gray-600">
              Company Name
            </label>
            <input
              type="text"
              id="company"
              name="company"
              className="mt-1 p-2 w-full border rounded-md border-gray-400 "
              value={formData.company}
              onChange={handleChange}
            />
          </div>


          <div>
            <label htmlFor="message" className="block text-sm font-medium text-gray-600">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              rows="4"
              className="mt-1 p-2 w-full border rounded-md border-gray-400 "
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>

          <button type="submit" className="border  w-[200px] p-3 text-center text-teal-400 font-bold  rounded-full font-mono border-teal-200  border-b-4 hover:border-teal-400">
            SEND MESSAGE
          </button>
        </form>
       
        {formFilled && (
          <p className="mt-4 text-green-600 font-medium">
            Thank you! Your form has been successfully filled.
          </p>
        )}
      </div>
      </div>
    </div>
    </>
  );
};

export default ContactPage;



  import React from 'react'
import ScheduleCall from './ScheduleCall';
  
  const slides = [
    {
      image:
        'https://media.istockphoto.com/id/1314537683/vector/pending-payment-abstract-concept-vector-illustration.jpg?s=612x612&w=0&k=20&c=lxVZjSIrxixseAjEruNVaOKGz11LpZoF8e2DUjhgIEw=',
      title: 'Software Development',
      description:
        'Qrysalis provides bespoke software development services tailored to meet the unique needs and objectives of each client. From enterprise applications to mobile apps and custom solutions, our expert team delivers high-quality software that aligns with business goals. ',
    },
  
    {
      image:
        'https://img.freepik.com/free-vector/brainstorm-team-work-composition-with-side-view-man-working-table-with-laptop-gear-icons-with-arrow-illustration_1284-63562.jpg',
      title: 'Managed Services',
      description:
        "Qrysalis offers comprehensive managed IT services, including IT support, monitoring, maintenance, and disaster recovery. Our proactive approach ensures maximum uptime, security, and performance for our clients' IT environments.",
    },
    {
      image:
        'https://media.istockphoto.com/id/1408018577/vector/financial-audit-isolated-cartoon-vector-illustrations.jpg?s=612x612&w=0&k=20&c=n2ZL6JBqP0WgsdkDXOeh50cPxMMl5y5USG7kyGqs4hQ=',
      title: 'Operations Optimization',
      description:
        'At Qrysalis, we understand the critical role of efficient and streamlined operations in achieving business success. Thats why we offer a range of services specifically designed to help you reengineer existing processes, analyze data, automate workflow and deploy business process management systems. ',
    },
    {
      image:
        'https://media.istockphoto.com/id/1312093463/vector/sales-forcasting-abstract-concept-vector-illustration.jpg?s=612x612&w=0&k=20&c=PlzE5_l3b9QnhaDL3qkWguvQvoscii5UsAItQDvaXXs=',
      title: 'Automation',
      description:
        'Empower your operations with both software and IoT automation to elevate efficiency, minimize costs, and unlock growth. We implement Robotic Process Automation (RPA), intelligent process automation, and connected device integration across your core services, optimizing everything from development workflows to server management and service desk tasks.',
    },
    {
      image:
        'https://static.vecteezy.com/system/resources/previews/006/584/652/non_2x/illustration-graphic-cartoon-character-of-email-services-vector.jpg',
      title: 'Tech Consulting',
      description:
        'Qrysalis offers expert tech consultancy services to help businesses navigate the complexities of digital transformation. From strategic planning to implementation and ongoing support, we provide invaluable insights and guidance to drive success. ',
    },
    {
      image:
        'https://media.istockphoto.com/id/1316316582/vector/intelligent-interface-abstract-concept-vector-illustration.jpg?s=612x612&w=0&k=20&c=FCFm9vnTXMfhJkMNt9csqgXGWyGLf8DXLKTYAjgceKU=',
      title: 'Infrastructure Services',
      description:
        'Our infrastructure services encompass server deployment, cloud computing, network design, and security solutions. We ensure secure, reliable, and scalable infrastructure to support the evolving needs of modern businesses.',
    },
  ];
  
  
  export default slides;


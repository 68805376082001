import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollToTopButton from '../Down to top scroll/ScrollToButton';
// import './Navbar.css'; // Import your custom CSS file
import logo from '../../assets/2.png'
import Hero from '../Home/Hero/Hero';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isNavbarFixed, setIsNavbarFixed] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
    document.body.style.overflow = isMobileMenuOpen ? 'auto' : 'hidden';
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 0;
      setIsScrolled(scrolled);

      const threshold = 100;
      setIsNavbarFixed(window.scrollY <= threshold);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="scroll-smooth">
      <div
        className={` lg:flex grid   justify-center  items-center text-center lg:space-x-4 text-white fixed  w-screen z-10 p-11 transition-transform duration-500 h-full transform ${
          isMobileMenuOpen ? '-translate-y-0' : '-translate-y-full'
        }`}
        style={{ backgroundColor: '#003140' }}
      >
        <ul className="text-white justify-center lg:flex grid grid-flow-row lg:space-x-5 space-y-5 lg:space-y-0 hover:cursor-pointer font-bold lg:text-2xl lg:gap-9">
          <li  className="inline-block  border-b-2 border-transparent hover:border-blue-500 transition duration-300 ease-in-out md:w-auto lg:w-auto xl:w-auto 2xl:w-auto">
            <a href='#home'>Home</a>
          </li>
          <li className="inline-block  border-b-2 border-transparent hover:border-blue-500 transition duration-300 ease-in-out md:w-auto lg:w-auto xl:w-auto 2xl:w-auto">
          <a  onClick={toggleMobileMenu} href='#about'>About Us</a> 
          </li>
          <li className="inline-block  border-b-2 border-transparent hover:border-blue-500 transition duration-300 ease-in-out md:w-auto lg:w-auto xl:w-auto 2xl:w-auto">
          <a onClick={toggleMobileMenu} href='#about'>Our Products</a>
          </li>
          <li className="inline-block  border-b-2 border-transparent hover:border-blue-500 transition duration-300 ease-in-out md:w-auto lg:w-auto xl:w-auto 2xl:w-auto">
          <a onClick={toggleMobileMenu} href='#service'>Our Services</a>
          </li>
          <li className="inline-block  border-b-2 border-transparent hover:border-blue-500 transition duration-300 ease-in-out  md:w-auto lg:w-auto xl:w-auto 2xl:w-auto">
           <a onClick={toggleMobileMenu} href='#team'>Meet Our Team  </a>        </li>
          <li className="inline-block border-b-2 border-transparent hover:border-blue-500 transition duration-300 ease-in-out  md:w-auto lg:w-auto xl:w-auto 2xl:w-auto">
           <a onClick={toggleMobileMenu} href='#contact'>Contact Us   </a>       </li>
        </ul>
        <div className="lg:mt-[40%] lg:absolute flex">
          <Link to="https://www.facebook.com/qrysalis">
            <img
              src="https://static.vecteezy.com/system/resources/previews/023/986/613/non_2x/facebook-logo-facebook-logo-transparent-facebook-icon-transparent-free-free-png.png"
              alt="facebook"
              className="rounded-full w-10  m-1"
            />
          </Link>
          {/* <Link to="https://twitter.com/">
            <img
              src="https://cdn.icon-icons.com/icons2/4029/PNG/512/twitter_x_new_logo_x_rounded_icon_256078.png"
              alt="twitter"
              className="rounded-full  w-14 m-1"
            />
          </Link> */}
          <Link to="https://www.linkedin.com/company/qrysalis">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f8/LinkedIn_icon_circle.svg/2048px-LinkedIn_icon_circle.svg.png"
              alt="linkedin"
              className="rounded-full h-8 m-2"
            />
          </Link>
        </div>
      </div>

      <nav
        className={`w-full p-2 lg:top-2  ${
          isNavbarFixed ? 'fixed   z-10 ' : ''
        }`}
      >
        {' '}
        <ScrollToTopButton />
        <div className="container mx-auto relative  ">
          <Link to="/" className="">
            <img src={logo} alt='logo' className='lg:w-[100px] w-11 '/>
          </Link>
          <div
            className={`  ${isScrolled ? 'block  shadow-black shadow-lg' : ''}`}
          >
            <button
              className={`block absolute right-5  lg:top-5 top-3   ease-in-out rounded-sm -stroke text-white shadow-sm transition-transform duration-300  transform`}
              onClick={toggleMobileMenu}
            >
              {isMobileMenuOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="lg:h-8 lg:w-8 w-5 h-5    "
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="lg:h-8 lg:w-8 w-5 h-5  "
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
